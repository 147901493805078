<template>
  <div class="noti-list-container">
    <!--! Header row -->
    <div class="row-search">
      <template>
        <router-link :to="{ name: 'notification-create' }">
          <b-button class="create-new-button" variant="primary">
            新規作成
          </b-button>
        </router-link>
      </template>
    </div>
    <!-- Noti list -->
    <b-row>
      <b-col class="noti-list" cols="3">
        <template>
          <div class="ml-1">
            <b-tabs content-class="pt-1" fill>
              <b-tab active title="すべて" @click="getListSystemNotify">
                <b-nav-item class="nav-space" v-for="(item, index) in listNoti" :key="index"
                  :active="nav === index ? true : false" active-class="active-style" @click="setActive(index, item)">
                  <b-card-text class="noti-info">
                    <div class="noti-info-type" v-if="item.status === STATUS_NOFITY.draft">
                      <label class="label-type">下書き</label>
                      <span>
                        {{ formatDate(item.updated_at, 'yyyy/MM/DD HH:mm') }}
                      </span>
                    </div>
                    <div class="noti-info-type" v-else-if="item.status === STATUS_NOFITY.sending">
                      <label class="label-type" style="background: #9f8181;color: #ffffff"> 送信中</label>
                      {{ formatDate(item.updated_at, 'yyyy/MM/DD HH:mm') }}
                    </div>
                    <div class="noti-info-type" v-else-if="item.status === STATUS_NOFITY.sendFail">
                      <label class="label-type">送信失敗</label>
                      {{ formatDate(item.updated_at, 'yyyy/MM/DD HH:mm') }}
                    </div>
                    <div class="noti-info-type" v-else>
                      {{ formatDate(item.updated_at, 'yyyy/MM/DD HH:mm') }}
                    </div>
                    <div class="noti-info-title">{{ item.title }} </div>
                  </b-card-text>
                </b-nav-item>
              </b-tab>
              <b-tab title="投稿済み" @click="getListByStatus(1)">
                <b-nav-item v-if="item.status !== 2" class="nav-space" v-for="(item, index) in listNotiSent" :key="index"
                  :active="nav === index ? true : false" active-class="active-style" @click="setActive(index, item)">
                  <b-card-text class="noti-info">
                    <div class="noti-info-type">
                      {{ formatDate(item.sent_at, 'yyyy/MM/DD HH:mm') }}
                    </div>
                    <div class="noti-info-title">{{ item.title }} </div>
                  </b-card-text>
                </b-nav-item>
              </b-tab>
              <b-tab title="下書き" @click="getListByStatus(2)">
                <b-nav-item v-if="item.status === 2" class="nav-space" v-for="(item, index) in listNotiDraft" :key="index"
                  :active="nav === index ? true : false" active-class="active-style" @click="setActive(index, item)">
                  <b-card-text class="noti-info">
                    <div class="noti-info-type">
                      <label class="label-type">下書き</label>{{ formatDate(item.updated_at, 'yyyy/MM/DD HH:mm') }}
                    </div>
                    <div class="noti-info-title">{{ item.title }}</div>
                  </b-card-text>
                </b-nav-item>
              </b-tab>
            </b-tabs>
          </div>
          <div class="pagination pagination-notify ml-1">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalPage"
                :per-page="perPage"
                @change="pageChangeHandler"
            ></b-pagination>
          </div>
        </template>
      </b-col>
      <b-col class="noti-form" >
        <!-- ! Noti detail-->
        <validation-observer  v-slot="{invalid}" ref="simpleRulesCreate">
          <form @submit.prevent="submitForm()">
            <div class="noti-detail" :class="{ 'noti-detail-disable': disableForm }" >
              <div class="message-detail-container">
                <label v-if="form.status === 2">下書き</label>
                <label v-else>最終投稿日時：{{ formatDate(sent_at, 'yyyy/MM/DD HH:mm') }}</label>
                <b-form-group label-for="titleDetail">
                  <label class="noti-detail-label">タイトル</label>
                  <validation-provider name="タイトル " vid="title" #default="{ errors }" >
                    <b-form-input
                      v-model="form.title"
                      id="titleDetail"
                      :class="errors[0] ?'errors__validated' :''"
                      :readonly="disableForm"
                      />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <label for="textarea-default">内容</label>
                <validation-provider name="内容 " vid="content" #default="{ errors }">
                  <b-form-textarea id="textarea-default" rows="20"
                    v-model="form.content"
                    :class="errors[0] ?'errors__validated' :''"
                    :readonly="disableForm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="btn-groups" v-if="form.status === 2">
                <b-button @click="cancelSelect()" variant="outline-secondary">
                  キャンセル
                </b-button>
                <b-button variant="outline-secondary" @click="destroy(form.id)"> 削除</b-button>
                <b-button variant="outline-secondary" @click="updateDraft(form.id)"> 下書きへ保存
                </b-button>
                <b-button variant="primary" @click="sentDraft(form.id)">
                  送信
                </b-button>
              </div>
            </div>
          </form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BNav,
  BNavItem,
  BAvatar,
  BFormInput,
  BFormGroup,
  BButton, BTabs, BTab, BCardText, BFormTextarea, BPagination,
} from "bootstrap-vue";
import BCardCode from '@core/components/b-card-code'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { max, required } from "@validations";
import { apiService } from "@/services/api.service";
import { STATUS, SYSTEM_NOTIFY_STATUS ,STATUS_NOFITY } from "@/constant/constant";

export default {
  name: "notiList",
  components: {
    BFormTextarea,
    BCardCode,
    BTabs,
    BCardText,
    BTab,
    BRow,
    BCol,
    BNav,
    BNavItem,
    BAvatar,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BPagination,
  },
  data() {
    return {
      form: {
        sent_at: "",
        id: "1",
        status: SYSTEM_NOTIFY_STATUS,
        title: "",
        content: "",

      },
      perPage: 10,
      currentPage: 1,
      totalPage: "",
      nav: false,
      sent_at: '',
      updated_at: '',
      listNoti: [],
      listNotiSent: [],
      listNotiDraft: [],
      activeTab: "",
      disableForm: false,
      required,
      max,
      STATUS_NOFITY
    };
  },
  methods: {
    setActive(index, item) {
      this.nav = index;
      this.form.content = item.content;
      this.form.title = item.title;
      this.sent_at = item.sent_at;
      this.updated_at = item.updated_at;
      this.form.status = item.status;
      this.form.id = item.id;
      if( this.form.status !== 2) {
        this.disableForm = true;
      } else {
        this.disableForm = false;
      }
    },
    defaultActive(list) {
      this.nav = 0;
      this.form.content = list.content;
      this.form.title = list.title;
      this.sent_at = list.sent_at;
      this.updated_at = list.updated_at;
      this.form.status = list.status;
      this.form.id = list.id;
      if( this.form.status !== 2) {
        this.disableForm = true;
      } else {
        this.disableForm = false;
      }
    },

    // get list account
    async getListSystemNotify(page) {
      this.startLoading()
      try {
        let res = await apiService.get(`/notification/list?page=${page}`);
        if (res.data.code === STATUS.SUCCESS && res.data.data) {
          this.activeTab = 0;
          this.listNoti = res.data.data;
          this.perPage = res.data.pagination.per_page;
          this.currentPage = res.data.pagination.current_page;
          this.totalPage = res.data.pagination.total;
          this.defaultActive(this.listNoti[0]);
        }else{
          this.notifyError(res.data.message);
        }
      } catch (e) {
        this.notifyError(e.data.message)
      } finally {
        this.endLoading();
      }
    },
    //get notify by status
    async getListByStatus(status, page){
      this.startLoading();
      try{
        let res = await apiService.get(`/notification/list?noti_status=${status}&page=${page}`);
        if (res.data.code === STATUS.SUCCESS && res.data.data) {
          if(status === 1) {
            this.listNotiSent = res.data.data;
            this.activeTab = 1;
            this.defaultActive(this.listNotiSent[0]);
          } else {
            this.listNotiDraft = res.data.data;
            this.activeTab = 2;
            this.defaultActive(this.listNotiDraft[0]);
          }
          this.perPage = res.data.pagination.per_page;
          this.currentPage = res.data.pagination.current_page;
          this.totalPage = res.data.pagination.total;
        }else{
          this.notifyError(res.data.message);
        }
      }catch (e){
        this.notifyError(e.data.message)
      } finally {
        this.endLoading();
      }
    },
    updateDraft(id) {
      this.form.status = 2;
      this.updateNoti(id)
    },
    sentDraft(id) {
      this.form.status = 1;
      this.updateNoti(id)
    },
    cancelSelect() {
      this.nav = false;
      this.disableForm = !this.disableForm;
      this.form.title = '';
      this.form.content = '';
    },
    // update New Notify
    async updateNoti(id) {
      this.$refs.simpleRulesCreate.reset();
      this.startLoading();
        try {
          let res = await apiService.put(`/notification/${id}`, this.form)
          if (res.data.code === STATUS.SUCCESS){
            if(this.form.status === 1) {
            this.notifySuccess(res.data.message);
          } else {
            this.notifySuccess(STATUS.NOTIFICATION_SAVE_DRAFT_SUCCESS);
          }
            if(this.activeTab !== 0) {
              this.getListByStatus(2);
            } else {
              this.getListSystemNotify();
            }
          } else if (res.data.code === STATUS.BAD_REQUEST){
            this.$refs.simpleRulesCreate.setErrors(res.data.data);
            this.form.status = 2;
          } else {
            this.notifyError(res.data.message);
            this.form.status = 2;
          }
        } catch (e) {
          this.notifyError(e.data.message);
        } finally {
          this.endLoading();
      }
    },
    async destroy(id) {
      this.startLoading();
      try {
        let res = await apiService.delete(`/notification/${id}`)
          if(this.activeTab !== 0) {
            this.getListByStatus(this.activeTab);
          } else {
            this.getListSystemNotify();
          }
          this.notifySuccess(res.data.message);
          this.perPage = res.data.pagination.per_page;
          this.currentPage = res.data.pagination.current_page;
          this.totalPage = res.data.pagination.total;
        } catch (e) {
          this.notifyError(e.data.message);
        } finally {
          this.endLoading();
      }
    },
    pageChangeHandler(page){
      if(this.activeTab !== 0) {
        this.getListByStatus(this.activeTab, page).catch(err => {});
      } else {
        this.getListSystemNotify(page).catch(err => {});
      }
    }
  },

  mounted() {
    this.getListSystemNotify();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>

.noti-list-container {
  .create-new-button {
    padding: 10px 24px;
    margin-left: 20px;
    position: absolute;
    right: 32px;
    top: 20px;
    display: flex;

    a {
      color: white;
      text-decoration: none;
    }
  }

  .noti-list {
    display: flex;
    height: calc(100vh - 83px);
    min-width: 333px;
    box-sizing: border-box;
    flex-direction: column;
    padding: 15px 12px 20px;
    background: #F8F8F8;
    border-right: 1px solid #C7C7C7;

    flex: none;
    order: 0;
    flex-grow: 0;

    ul {
      border-bottom: 1px solid #dae1e7;
      list-style: none;
    }

    li {
      list-style: none;

      a {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
      }
    }

    .nav-link {
      color: #000000;
    }

    .nav-space {
      margin-bottom: 10px;

      a {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
      }

      .noti-info {
        .noti-info-title {
          white-space: nowrap;
          min-width: 293px;
          width: 18.5vw;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .noti-info-type {
          display: flex;

          .label-type {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 2px 6px;
            margin-right: 8px;
            gap: 10px;
            background: #E0E0E0;
            border-radius: 4px;

            flex: none;
            order: 0;
            flex-grow: 0;
            &--sent {
              background: #9f8181;
              color: #fff;
            }
          }
        }
      }
    }

    .nav-item .active {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 10px;
      gap: 2px;
      color: white;
      background: #377DFF;
      border-radius: 10px;

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

  }
  .noti-detail-disable {
    pointer-events: none;
  }
  .noti-form {
    background: #F5F4F6;
    padding-left:0 !important
  }
  .noti-detail {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding: 30px 40px;
    gap: 16px;
    height: 813px;
    background: #F5F4F6;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    .noti-detail-label {
      margin-top: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    .btn-groups {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 10px;
      }
    }
  }
  .pagination-notify {
    float: right;
    bottom: 15px;
    margin-top: auto;
  }
}
</style>
